.container {
    margin: 20px;
    font-family: Arial, sans-serif;
  }
  
  .form {
    margin-bottom: 20px;
  }
  
  .label {
    margin-right: 10px;
  }
  
  .select {
    margin-right: 10px;
    padding: 8px;
    border-radius: 4px;
  }
  
  .button {
    padding: 8px 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #45a049;
  }
  