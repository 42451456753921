/* ChatComponent.css */

/* Ensuring the body takes full width and has no margin or padding */
body {
  margin: 0;
  padding: 0;
  width: 100%;
}

/* Full-width chat container */
.chat-container {
  width: 100%; /* Ensuring the container takes full width */
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box; /* Includes padding in width calculation */
}

/* Styles for the chat box */
.chat-box {
  width: 100%; /* Full width for the chat box */
  overflow-y: auto; /* Allowing scroll on y-axis */
  margin-bottom: 5px;
  height: 250px; /* Fixed height or adjustable based on your design */
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size:medium;
}

/* Styles for the input form */
.chat-form {
  width: 100%; /* Ensuring form takes full width */
  display: flex;
}

input[type="text"] {
  flex-grow: 1; /* Input takes all available space except button */
  margin-right: 10px; /* Spacing between input and button */
}

button {
  width: auto;
}

/* ChatComponent.css */

/* Existing styles... */

input[type="text"] {
  flex-grow: 1; /* Input takes all available space except button */
  margin-right: 10px; /* Spacing between input and button */
  background-color: #333; /* Dark background for the input */
  color: #fff; /* Light color for the text */
  border: 1px solid #555; /* Darker border for better visibility */
  padding: 8px 10px; /* Padding for the input */
  font-size: 16px; /* Adjust font size as needed */

  /* Styling the placeholder text */
  ::placeholder {
    color: #aaa; /* Light grey color for placeholder text */
  }
}

.container {
  width: 100%; /* Ensures the container takes the full width */
  display: flex;
  flex-direction: column;
}

#map {
  width: 100%; /* Ensures the map takes the full width */
}

.chat-container {
  width: 100%; /* Ensures the chat container takes the full width */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chat-form {
  width: 100%; /* Ensures the form takes the full width */
  display: flex;
  justify-content: center;
}

.user-message {
  text-align: right;
  font-family: 'Times New Roman', Times, serif;
  color: #69aaff;
}

.ai-message {
  text-align: left;
  font-family: 'Times New Roman', Times, serif;
  color: #d8dde4;
}


/* Existing styles... */
