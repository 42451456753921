.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px; /* Adjust margin as needed */
  flex-wrap: wrap; /* Allow buttons to wrap to the next line on smaller screens */
}

.elegantButton {
  flex: 1; /* Distribute available space equally among buttons */
  max-width: 200px;
  padding: 10px 20px;
  margin: 5px;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid #007bff;
  border-radius: 5px;
  background-color: #007bff;
  color: #ffffff;
  cursor: pointer;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.elegantButton:hover {
  background-color: #5a8ac8; /* Darker shade of light blue for hover effect */
}

/* Media query for mobile devices */
@media only screen and (max-width: 600px) {
  .elegantButton {
    flex: 1 0 calc(50% - 10px); /* Make the button take up 50% of the width with padding in between */
    margin: 5px; /* Adjust margin for spacing between buttons in mobile view */
  }
}

.App-logo {
  width: 40%; /* Adjusts the width to half of its parent container */
  height: auto; /* Maintains the aspect ratio */
}

