body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #1e1e1e;
  color: #fff;
}

header {
  background-color: #262626;
  padding: 7px;
  text-align: center;
  color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

i {
  color: #3498db;
}

h1 {
  margin: 0;
  font-size: 2em;
}

#logo {
  width: 35px;
  height: auto;
  margin-right: 10px;
}

main {
  padding: 1em;
  margin-bottom: 60px;
}

footer {
  background-color: #262626;
  padding: 1em;
  text-align: center;
  color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

nav {
  position: relative;
}

#menuToggle {
  display: block;
  position: fixed;
  top: 1em;
  right: 1em;
  z-index: 1000;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a {
  text-decoration: none;
  color: #ffffff;
  transition: color 0.3s ease;
}

#menuToggle a:hover {
  color: tomato;
}

#menuToggle input {
  display: block;
  width: 25px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
}

#menuToggle span {
  display: block;
  width: 28px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #cdcdcd;
  border-radius: 2px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #7e7373;
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}


#menu {
position: fixed;
width: 180px;
margin: 0;
margin-left:60px;
margin-right:20px;
padding: 50px;
padding-top: 20px;
background: #262626;
list-style-type: none;
-webkit-font-smoothing: antialiased;
transform-origin: 0% 0%;
transform: translate(-100%, 0);
transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
z-index: 999; /* Add this line to ensure the menu is above other content */
}

#menu li {
  padding: 3px 0;
  font-size: 15px;
}

#menuToggle input:not(:checked) ~ #menu {
  transform: translate(0, 0);
}

#menuToggle input:checked ~ #menu {
  transform: translate(-100%, 0);
}

/* Add this style to your existing styles or in a separate stylesheet */
a:link, a:visited {
  color: inherit; /* This will inherit the color from the parent element */
  text-decoration: none; /* Remove underline for the link */
}

a:hover {
  /* Optional: Add styles for the link when hovered */
  color: #007bff; /* Change to the desired hover color */
}